import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "components/Layouts/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "components/FooterPages";
import AccordionUsage from "components/Accordion";
import { Button } from "@mui/material";


function Treinamentos() {

  const onClickRedirect = (servico) =>{
    const url = `https://www.youtube.com/@CCXSolucoes`;
    window.open(url,'_blank');
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <AccordionUsage 
          listAccordion={[
            {
              title: 'Veja nossos treinamentos',
              defaultExpanded: true,
              details: 'Os treinamentos abrangem vendas e a utilização eficaz do PIX parcelado. A equipe será capacitada não apenas em técnicas de vendas, mas também em como explorar todo o potencial do PIX parcelado. Isso inclui orientações sobre configuração, processamento de transações, resolução de problemas e comunicação eficaz sobre as vantagens desse método de pagamento.',
              buttons: <Button onClick={()=>onClickRedirect()}>Acesse agora</Button>
            },
          ]}
        />
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Treinamentos;
