import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "components/Layouts/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "components/FooterPages";
import AccordionUsage from "components/Accordion";
import { Button } from "@mui/material";


function CampIndicacao() {

  const onClickRedirect = () =>{
    const url = `https://bit.ly/Indica%C3%A7%C3%A3o2024`;
    window.open(url,'_blank');
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3} style={{display: 'grid', gap: '10px'}}>
        <AccordionUsage 
          listAccordion={[
            {
              title: 'Programa de Indicação CCX: Economize Indicando!',
              defaultExpanded: true,
              details: 'Quer ganhar descontos nos outros produtos da CCX? Indique seus amigos! Cada indicação que se tornar cliente significa desconto para você, até 60%! Não perca essa chance de economizar e compartilhar os benefícios da CCX. Indique agora!',
              buttons: <Button onClick={()=>onClickRedirect()}>Descubra agora</Button>
            },
          ]}
        />
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default CampIndicacao;
