import Icon from "@mui/material/Icon";
import Dashboard from "layouts/dashboard";
import CadastroCliente from "layouts/cadastroCliente";
import ListarClientes from "layouts/listarClientes";
import NossosServicos from "layouts/nossosServicos";
import Treinamentos from "layouts/treinamento";
import GestaoContratos from "layouts/gestaoContratos";
import Campanhas from "layouts/campanhas";
import CcxDev from "layouts/ccxDev";
import CampIndicacao from "layouts/campIndicacao";
import MeusDados from "layouts/meusDados";

export const RoutesPaths = {
  dashboard: "/dashboard",
  cadastroCliente: "/cadastroCliente",
  listarCliente: "/listarCliente",
  contrato: "/linhasCredito",
  campanhas: "/campanhas",
  meusDados: "/meusDados",
  treinamentos: "/treinamentos",
  indicacao: "/indicacao",
  nossosServicos: "/nossosServicos",
  ccxDev: "/ccxDev",
  cadastro: "/cadastro"
}

export const Routers = [
  {
    type: "collapse",
    name: "Painel",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: RoutesPaths.dashboard,
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Cadastrar cliente",
    key: "register",
    icon: <Icon fontSize="small">person_add</Icon>,
    route: RoutesPaths.cadastroCliente,
    component: <CadastroCliente />,
    onlyAdm: true
  },
  {
    type: "collapse",
    name: "Listar clientes",
    key: "registerList",
    icon: <Icon fontSize="small">person</Icon>,
    route: RoutesPaths.listarCliente,
    component: <ListarClientes />,
    onlyAdm: true
  },
  {
    type: "collapse",
    name: "Linhas de crédito",
    key: "contratos",
    icon: <Icon fontSize="small">checklist</Icon>,
    route: RoutesPaths.contrato,
    component: <GestaoContratos />,
  },
  {
    type: "collapse",
    name: "Campanhas",
    key: "campanhas",
    icon: <Icon fontSize="small">star</Icon>,
    route: RoutesPaths.campanhas,
    component: <Campanhas />,
  },
  {
    type: "collapse",
    name: "Meus Dados/Contratos",
    key: "meusDados",
    icon: <Icon fontSize="small">lock</Icon>,
    route: RoutesPaths.meusDados,
    component: <MeusDados />,
  },
  {
    type: "collapse",
    name: "Treinamentos",
    key: "treinamentos",
    icon: <Icon fontSize="small">work</Icon>,
    route: RoutesPaths.treinamentos,
    component: <Treinamentos />,
  },
  {
    type: "collapse",
    name: "Programa de Indicação",
    key: "indicacao",
    icon: <Icon fontSize="small">person</Icon>,
    route: RoutesPaths.indicacao,
    component: <CampIndicacao />,
  },
  {
    type: "collapse",
    name: "CCX Sales",
    key: "servicos",
    icon: <Icon fontSize="small">trending_up</Icon>,
    route: RoutesPaths.nossosServicos,
    component: <NossosServicos />,
  },
  {
    type: "collapse",
    name: "CCX Dev",
    key: "dev",
    icon: <Icon fontSize="small">code</Icon>,
    route: RoutesPaths.ccxDev,
    component: <CcxDev />,
  }
];
