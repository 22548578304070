import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import DashboardLayout from "components/Layouts/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "components/FooterPages";
import ComplexStatisticsCardWithBtn from "examples/Cards/StatisticsCards/ComplexStatisticsCardWithBtn";

function GestaoContratos() {
  
  const onClickContrato = () => {
    const url = `https://docs.google.com/forms/d/e/1FAIpQLScIvPL05eRFBoDaFNK_HpimP1GjKQ8aqqg5gordIPPBz1fkLg/viewform`;
    window.open(url, "_blank");
  };

  const onClickApp24x = () => {
    const url = `https://bit.ly/Cadastro24xCCXBank`;
    window.open(url, "_blank");
  };


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3} height={"84vh"}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCardWithBtn
                icon="queue"
                color="success"
                title="Pix em até 24x"
                labelBtn="Preencha o formulário"
                onClickBtn={onClickApp24x}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCardWithBtn
                icon="attach_money"
                color="error"
                title="Aplicação Crédito Cidadão"
                labelBtn="Preencha o formulário"
                onClickBtn={onClickContrato}
              />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default GestaoContratos;
