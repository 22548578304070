// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "components/Layouts/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "components/FooterPages";
import DataTable from "components/Tables/DataTable";

// Data
import clientsData from "./data/tableData";

function ListarClientes() {
  const { rows } = clientsData();

  const columns = [
    { Header: "nome/razão social", accessor: "author", width: "45%", align: "left" },
    { Header: "cnpj/url", accessor: "function", align: "left" },
    { Header: "status", accessor: "status", width: "50%", align: "left" },
    { Header: "Contrato & Onboarding Check", accessor: "contract", align: "center" },
    { Header: "Ações", accessor: "action", align: "center" },
  ]

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Clientes
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted
                  entriesPerPage
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ListarClientes;
