// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "components/Layouts/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "components/FooterPages";
import AccordionUsage from "components/Accordion";
import { Button } from "@mui/material";

const whatsNumber = '12983111505'

function NossosServicos() {

  const onClickWhats = (servico) =>{
    const url = `https://api.whatsapp.com/send?phone=${whatsNumber}&text=Gostaria+de+saber+mais+sobre+o+${servico.replaceAll(' ','+')}+%2C+vim+pelo+portal`;
    window.open(url,'_blank');
  }

  const onClickServico = (url) => {
    window.open(url,'_blank');
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <AccordionUsage 
          listAccordion={[
            {
              title: 'Serviço de Social Media',
              defaultExpanded: true,
              details: 
              <div>
                <span>Impulsionamos a forma como você é visto nas redes sociais, conectando-se com seu público-alvo de forma autêntica e envolvente através do nosso serviço de Social Media. Nossa equipe especializada cria e gerencia estratégias personalizadas para suas plataformas de mídia social, ampliando o alcance da sua marca e gerando interações valiosas. Deixe-nos cuidar do seu perfil online enquanto você se concentra no que faz de melhor. Alcance resultados tangíveis com nossa abordagem focada e eficaz em Social Media. Contrate agora mesmo nossos serviços e comece a alcançar o sucesso nas redes sociais!</span>
                <br />
                <br /> 
                <b>Plano:</b>
                <br /> 
                Facebook e Instagram 
                <br /> 
                8 Posts - FEED <br /> 
                3 Posts - Stories<br /> 
                2 Artes personalizada<br /> 
                <br /> 
                Obs: Todos os posts para sua empresa serão produzidos com a identidade visual da sua marca e poderão ser usados nos posts materiais como fotos, vídeos de produtos etc.<br /> 
                <br /> 
                2 ajustes em cada post já inclusos<br /> 
                R$ 499,00/mês
              </div>
              ,
              buttons: <>
                <Button onClick={()=>onClickWhats('Servico de Social Media')}> Saiba Mais</Button>
                <Button onClick={()=>onClickServico('https://buy.stripe.com/fZedUbc6E3Np3YcdQS')}> Contrate já</Button>
              </>
            },
            {
              title: 'Serviço de Tráfego Pago (Anúncios Pagos)',
              defaultExpanded: false,
              details: 
              <div>
                <span>Alcance novos patamares de visibilidade e alcance seu público-alvo de forma direta e impactante com nosso serviço de Tráfego Pago. Nossa equipe especializada projeta e executa campanhas personalizadas, direcionando anúncios estrategicamente para maximizar o retorno sobre o investimento. Otimize seu potencial de conversão com Tráfego Pago. Clique agora mesmo e alcance resultados excepcionais com seus anúncios online!</span>
                <br />
                <br /> 
                <b>Plano:</b>
                <br /> 
                  Anúncios vinculados no Instagram e Facebook + 3 Artes personalizada para os anúncios. 
                <br /> 
                <br /> 
                Obs: Materiais da empresa já existentes poderão ser usadas nos anúncios como fotos, posts, vídeos de produtos etc<br /> 
                <br /> 
                Investimento limitado em até R$1.500,00 para anúncios<br /> 
                R$ 699,00/mês
              </div>
              ,
              buttons: <>
                <Button onClick={()=>onClickWhats('Servico de Trafego Pago (Anuncios Pagos)')}> Saiba Mais</Button>
                <Button onClick={()=>onClickServico('https://buy.stripe.com/bIY9DV5Ig83FcuI28b')}> Contrate já</Button>
              </>
            },
            {
              title: 'Google Ads',
              defaultExpanded: false,
              details: 
              <div>
                <span>Potencialize sua presença online e atinja seu público-alvo no momento certo com o Google Ads. Nossa equipe altamente qualificada cria campanhas de anúncios personalizadas, otimizando palavras-chave e segmentação para maximizar o retorno sobre o investimento. Faça com que sua marca chegue a cada vez mais internautas e conquiste mais clientes com o Google Ads. Decida por  impulsionar o crescimento do seu negócio contratando nossos serviços!</span>
                <br />
                <br /> 
                <b>Plano:</b>
                <br /> 
                Anúncios vinculados no Google ads + 2 Artes personalizada para os anúncios.
                <br /> 
                <br /> 
                Obs: Materiais da empresa já existentes poderão ser usadas nos anúncios como fotos, posts, vídeos de produtos etc<br /> 
                <br /> 
                Investimento limitado em até R$2.000,00 para anúncios<br /> 
                R$ 699,00/mês
              </div>
              ,
              buttons: <>
                <Button onClick={()=>onClickWhats('Google Ads')}> Saiba Mais</Button>
                <Button onClick={()=>onClickServico('https://buy.stripe.com/28o7vN0nW83FeCQbIM')}> Contrate já</Button>
              </>
            },
            {
              title: 'Google Meu Negócio',
              defaultExpanded: false,
              details: 
              <div>
                <span>Destaque-se nos resultados de pesquisa locais e impulsione o tráfego para sua empresa com o Google Meu Negócio. Nossa equipe especializada otimiza seu perfil comercial, garantindo que você seja encontrado por clientes locais quando mais precisam de você. Aumente sua visibilidade local e atraia mais clientes  no Google Meu Negócio. Coloque sua empresa no mapa contratando nossa equipe agora mesmo!</span>
                <br />
                <br /> 
                <b>Plano:</b>
                <br /> 
                Criação ou Atualização + 3 Artes personalizada para o Google Meu Negócio.
                <br /> 
                <br /> 
                Obs: Materiais da empresa já existentes poderão ser usadas nos anúncios como fotos, posts, vídeos de produtos etc<br /> 
                <br /> 
                R$ 299,00/taxa única
              </div>
              ,
              buttons: <>
                <Button onClick={()=>onClickWhats('Google Meu Negocio')}> Saiba Mais</Button>
                <Button onClick={()=>onClickServico('https://buy.stripe.com/dR617p1s05Vx2U84gl')}> Contrate já</Button>
              </>
            },
            {
              title: 'Implantação de Sistema de WhatsApp',
              defaultExpanded: false,
              details: 
              <div>
                <span>Simplifique sua comunicação e fortaleça o relacionamento com seus clientes através da implantação de um sistema de WhatsApp. Nossa equipe especializada integra e configura o WhatsApp Business para sua empresa, permitindo que você se conecte de forma rápida e eficiente com seu público-alvo. construa relacionamentos duradouros com seus clientes enquanto nós cuidamos da sua comunicação com eles. Melhore sua interação e impulsione a satisfação do cliente com nossa solução especializada em WhatsApp. Leve sua comunicação para o próximo nível, contrate-nos já!</span>
                <br />
                <br /> 
                <b>Plano:</b>
                <br /> 
                Treinamento de usabilidade da ferramenta para a liderança da empresa<br /> 
                Treinamento de usabilidade da ferramenta para atendentes <br /> 
                Configuração completa e personalizada para sua empresa<br /> 
                Atendimento de suporte personalizado <br /> 
                <br /> 
                Obs: O valor cobrado pela CCX é para o setup da ferramenta, será necessário contratar o plano da ferramenta de whatsapp.
                <br /> 
                Planos da ferramenta:<br /> 
                - Qtd Usuários - Valor<br /> 
                <b>
                  2 - R$ 169,00<br /> 
                  4 - R$ 219,00<br /> 
                  8 - R$ 439,00<br /> 
                  12 - R$ 699,00<br /> 
                  20 - R$ 969,00<br /> 
                </b>
                <br /> 
                R$ 899,00/setup
              </div>
              ,
              buttons: <>
                <Button onClick={()=>onClickWhats('Implantacao de sistema de whatsapp')}> Saiba Mais</Button>
                <Button onClick={()=>onClickServico('https://buy.stripe.com/eVa6rJeeMgAbdyMaEK')}> Contrate já</Button>
              </>
            },
            {
              title: 'Construção de Sites Institucionais',
              defaultExpanded: false,
              details: 
              <div>
                <span>Destaque-se online com um site institucional profissional e impactante. Nossa equipe de design e desenvolvimento cria sites personalizados, alinhados à identidade da sua marca e otimizados para resultados. Concentra-se em expandir seus negócios e nos deixe criar um site de qualidade por uma equipe especializada. Faça sua credibilidade crescer exponencialmente e atrair mais clientes com nosso serviço de construção de sites institucionais. Nos contratando agora você ganha tempo em relação aos seus concorrentes e estabelece uma presença online poderosíssima!</span>
                <br />
                <br /> 
                <b>Plano:</b>
                <br /> 
                Todo o desenvolvimento do seu Site Institucional será personalizado para a sua marca seguindo as seguintes etapas<br /> 
                Reunião para elaborar a proposta<br /> 
                Briefing do projeto<br /> 
                Entrega 1 - ajustes<br /> 
                Entrega 2 - ajustes<br /> 
                Entrega final<br /> 
                <br /> 
                Obs: Após o pagamento da taxa administrativa nosso time irá entrar em contato para colher as informações necessárias para a elaboração da proposta e explicar com mais detalhe todo o processo. Caso a proposta comercial não seja aceita o valor da taxa administrativa será estornado 100% em até 7 dias.
                <br /> <br /> 
                R$ 99,00/Taxa Administrativa 
              </div>
              ,
              buttons: <>
                <Button onClick={()=>onClickWhats('Construcao de Sites Institucionais')}> Saiba Mais</Button>
                <Button onClick={()=>onClickServico('https://buy.stripe.com/3cs03lc6EabNbqE9AH')}> Contrate já</Button>
              </>
            },
            {
              title: 'Construção de Landing Page',
              defaultExpanded: false,
              details: 
              <div>
                <span>Capte leads e impulsione as conversões com uma landing page altamente otimizada. Nossa equipe especializada desenvolve landing pages personalizadas, projetadas para maximizar as taxas de conversão e direcionar o tráfego para suas ofertas. Aumente o sucesso das suas campanhas de marketing com nossa abordagem especializada na construção de landing pages. Receba novos clientes enquanto você vende, contrate agora e comece a gerar mais leads!</span>
                <br />
                <br /> 
                <b>Plano:</b>
                <br /> 
                Todo o desenvolvimento da sua Landing Page será personalizado para a sua marca seguindo as seguintes etapas<br /> 
                Reunião para elaborar a proposta<br /> 
                Briefing do projeto<br /> 
                Entrega 1 - ajustes<br /> 
                Entrega 2 - ajustes<br /> 
                Entrega final<br /> 
                <br /> 
                Obs: Após o pagamento da taxa administrativa nosso time irá entrar em contato para colher as informações necessárias para a elaboração da proposta e explicar com mais detalhe todo o processo. Caso a proposta comercial não seja aceita o valor da taxa administrativa será estornado 100% em até 7 dias.
                <br /> <br /> 
                R$ 99,00/Taxa Administrativa 
              </div>
              ,
              buttons: <>
                <Button onClick={()=>onClickWhats('Construcao de landing page')}> Saiba Mais</Button>
                <Button onClick={()=>onClickServico('https://buy.stripe.com/dR65nF7Qo4RtfGUbIQ')}> Contrate já</Button>
              </>
            },
            {
              title: 'Construção de E-commerce',
              defaultExpanded: false,
              details: 
              <div>
                <span>Entre no mundo do comércio online com um e-commerce profissional e eficaz. Nossa equipe especializada cria lojas virtuais personalizadas, projetadas para oferecer uma experiência de compra excepcional aos seus clientes. Aumente suas vendas e expanda seus negócios com nossa construção focada e eficaz de e-commerce. Contrate agora mesmo nossos serviços e comece a vender online com sucesso!</span>
                <br />
                <br /> 
                <b>Plano:</b>
                <br /> 
                Todo o desenvolvimento do seu E-commerce será personalizado para a sua marca seguindo as seguintes etapas<br /> 
                Reunião para elaborar a proposta<br /> 
                Briefing do projeto<br /> 
                Entrega 1 - ajustes<br /> 
                Entrega 2 - ajustes<br /> 
                Entrega final<br /> 
                <br /> 
                Obs: Após o pagamento da taxa administrativa nosso time irá entrar em contato para colher as informações necessárias para a elaboração da proposta e explicar com mais detalhe todo o processo. Caso a proposta comercial não seja aceita o valor da taxa administrativa será estornado 100% em até 7 dias.
                <br /> <br /> 
                R$ 99,00/Taxa Administrativa 
              </div>
              ,
              buttons: <>
                <Button onClick={()=>onClickWhats('Construcao de e-commerce')}> Saiba Mais</Button>
                <Button onClick={()=>onClickServico('https://buy.stripe.com/5kA7vNgmU6ZBdyM009')}> Contrate já</Button>
              </>
            },
          ]}
        />
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default NossosServicos;
