import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';
import { 
  getFirestore, 
  collection, addDoc, 
  query, where, getDocs, updateDoc, deleteDoc 
} from 'firebase/firestore';
import { firebaseApp } from "./firebase";

const auth = getAuth(firebaseApp)
const firestore = getFirestore();

export const criarUserFirebase = async (email, senha) =>{
  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email, senha);
    return userCredential.user;
  } catch (error) {
    throw new Error('Erro ao criar usuário no Firebase Authentication: ' + error.message);
  }
}

export const salvarInformacoesNoFirestore = async (values) => {
  try {
    const docRef = await addDoc(collection(firestore, 'clientes'), { ...values, senha: '#secret#' });
    return docRef.id;
  } catch (error) {
    throw new Error('Erro ao salvar informações no Firestore: ' + error.message);
  }
};

export const atualizarInformacoesFireStore = async (valuesToUpdate) => {
  const uid = valuesToUpdate?.uid;

  try {
    // Consulta para obter o documento com o UID fornecido
    const q = query(collection(firestore, 'clientes'), where('uid', '==', uid));
    const querySnapshot = await getDocs(q);
    
    if (!querySnapshot.empty) {
      // Atualiza cada documento retornado pela consulta
      querySnapshot.forEach(async (doc) => {
        await updateDoc(doc.ref, valuesToUpdate);
      });
    } else {
      console.log(`Nenhum cliente encontrado com o UID ${uid}.`);
    }
  } catch (error) {
    throw new Error('Erro ao atualizar informações no Firestore: ' + error.message);
  }
};

export const obterDadosCliente = async (uid)=> {
  try {
    const q = query(collection(firestore, 'clientes'), where('uid', '==', uid));

    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const primeiroDocumento = querySnapshot.docs[0];
      const dadosCliente = primeiroDocumento.data();
      return dadosCliente;
    } else {
      return null;
    }
  } catch (error) {
    throw new Error('Erro ao obter informações do Firestore: ' + error.message);
  }
};

export const obterDadosAdmin = async (uid)=> {
  try {
    const q = query(collection(firestore, 'admin'), where('uid', '==', uid));

    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const primeiroDocumento = querySnapshot.docs[0];
      const dadosCliente = primeiroDocumento.data();
      return dadosCliente;
    } else {
      return null;
    }
  } catch (error) {
    throw new Error('Erro ao obter informações do Firestore: ' + error.message);
  }
};

export const listarTodosClientes = async ()=> {
  try {
    const q = query(collection(firestore, 'clientes'));

    const querySnapshot = await getDocs(q);
    
    const listaClientes = [];

    if (!querySnapshot.empty) {
      querySnapshot.forEach((doc) => {
        const dadosCliente = doc.data();
        listaClientes.push(dadosCliente);
      });

      return listaClientes;
    } else {
      return null;
    }
  } catch (error) {
    throw new Error('Erro ao obter informações do Firestore: ' + error.message);
  }
};

export const excluirClienteUsuario = async (uid) =>{
  try {
    const q = query(collection(firestore, 'clientes'), where('uid', '==', uid));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      querySnapshot.forEach(async (doc) => {
        await deleteDoc(doc.ref);
      });
    }
  } catch (error) {
    throw new Error('Erro ao excluir usuário: ' + error.message);
  }
}