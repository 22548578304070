import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "components/Layouts/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "components/FooterPages";
import AccordionUsage from "components/Accordion";

function Campanhas() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <AccordionUsage 
          listAccordion={[
            {
              title: 'Campanhas',
              defaultExpanded: true,
              details: 'É uma funcionalidade estratégica utilizada para promover iniciativas de incentivo direcionadas aos colaboradores da empresa. Estas campanhas têm como objetivo primário motivar, engajar e recompensar os funcionários, estimulando um ambiente de trabalho produtivo e positivo. Aqui, você encontrará todas as campanhas da financeira, junto com os mecanismos de recompensas associados.',
              buttons: <></>
            },
          ]}
        />
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Campanhas;
