import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "components/Layouts/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "components/FooterPages";
import AccordionUsage from "components/Accordion";
import { Button } from "@mui/material";


function CcxDev() {

  const onClickRedirect = (servico) =>{
    const url = `https://bit.ly/Aplica%C3%A7%C3%A3oDev`;
    window.open(url,'_blank');
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3} style={{display: 'grid', gap: '10px'}}>
        <AccordionUsage 
          listAccordion={[
            {
              title: 'Descubra o Potencial Ilimitado do Seu Projeto com a CCX Dev!',
              defaultExpanded: true,
              details: 'Estamos ansiosos para colaborar e elevar o seu projeto a novos patamares de sucesso! Na CCX Dev, somos especialistas em transformar ideias em realidade digital. Com nossa experiência e paixão pelo desenvolvimento de aplicativos e sistemas, estamos prontos para fazer do seu projeto um verdadeiro sucesso.',
              buttons: <Button onClick={()=>onClickRedirect()}>Descubra agora</Button>
            },
          ]}
        />
        <AccordionUsage 
          listAccordion={[
            {
              title: 'Explorando o Futuro: Pré-lançamento IA de Análise de Dados de Vendas',
              defaultExpanded: false,
              details: <div>
              Preparando-se para uma mudança de paradigma? Estamos emocionados em compartilhar uma prévia do nosso projeto revolucionário em desenvolvimento: uma Inteligência Artificial (IA) de análise de dados de vendas. Este projeto está sendo meticulosamente concebido para oferecer um novo horizonte de compreensão e aproveitamento dos dados de vendas, prometendo insights que impulsionarão o crescimento e o sucesso das empresas.
              <br /><br />
              <b>Destaques do Projeto:</b>
              <br /><br />
              <b>Análise Avançada de Dados:</b> Nossa IA está sendo criada para mergulhar profundamente nos vastos oceanos de dados de vendas, identificando padrões sutis e tendências ocultas que escapariam à percepção humana.
              <br /><br />
              <b>Geração de Insights Estratégicos:</b> Estamos dedicando esforços significativos para assegurar que a IA seja capaz de extrair insights estratégicos acionáveis, capacitando as empresas a identificar oportunidades latentes, antecipar mudanças no mercado e tomar decisões fundamentadas.
              <br /><br />
              <b>Personalização e Adaptabilidade:</b> Visamos oferecer uma solução altamente personalizável e adaptável às necessidades únicas de cada empresa, permitindo que definam critérios específicos de análise e recebam recomendações precisas e pertinentes.
              <br /><br />
              <b>Eficiência e Agilidade:</b> Estamos comprometidos em criar uma solução que não apenas forneça insights valiosos, mas também seja ágil e eficiente, permitindo que as empresas ajam rapidamente para capitalizar as oportunidades identificadas.
              <br /><br />
              Com nossa IA, você poderá encontrar oportunidades em sua base que irão aumentar seu faturamento e a se comunicar de forma mais eficaz com seus clientes. Fique atento para mais atualizações essa inovação está prestes a transformar o mundo dos negócios!
              </div>,
              buttons: <Button>Em breve</Button>
            },
          ]}
        />
        <AccordionUsage 
          listAccordion={[
            {
              title: 'Descubra o Futuro da Gestão Empresarial com o Novo Sistema ERP da CCX!',
              defaultExpanded: false,
              details: <div>
                Preparando-se para revolucionar a forma como as empresas gerenciam seus processos, a CCX está prestes a lançar o sistema ERP (Enterprise Resource Planning). Com uma abordagem inovadora e foco na eficiência, nosso sistema ERP oferecera uma solução abrangente para integrar, automatizar e otimizar todos os aspectos da gestão empresarial.
                <br /><br />
                <b>Recursos Inovadores:</b>
                <br /><br />
                <b>Integração Total: </b>Conecte todos os departamentos e processos da sua empresa em uma única plataforma integrada.
                <br />
                <b>Automatização Inteligente:</b> Reduza o trabalho manual e aumente a eficiência com recursos avançados de automação.
                <br />
                <b>Análise Poderosa:</b> Tome decisões informadas com base em dados precisos e insights acionáveis fornecidos pelo nosso sistema de análise avançada.
                <br />
                <b>Flexibilidade e Escalabilidade:</b> Adapte-se facilmente às necessidades em constante evolução da sua empresa com um sistema flexível e escalável.
                <br />
                <b>Segurança Avançada:</b> Proteja os dados da sua empresa com recursos de segurança robustos e em conformidade com os mais altos padrões da indústria.
                <br />
                Com o novo sistema ERP da CCX, você terá a ferramenta perfeita para impulsionar a eficiência operacional, aumentar a produtividade e alcançar o sucesso sustentável.
                <br /><br />
                <b>Prepare-se para o Futuro da Gestão Empresarial com a CCX!</b>
              </div>,
              buttons: <Button>Em breve</Button>
            },
          ]}
        />
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default CcxDev;
