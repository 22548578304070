import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "components/Layouts/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "components/FooterPages";
import { AuthContext } from "context";
import { useContext } from "react";
import { Grid } from "@mui/material";
import ComplexStatisticsCardWithBtn from "examples/Cards/StatisticsCards/ComplexStatisticsCardWithBtn";

function MeusDados() {
  const authContext = useContext(AuthContext);
  const {profile} = authContext;

  const onClickContrato = () => {
    const url = `https://bit.ly/SoluçõesCCXBank`;
    window.open(url, "_blank");
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <div
          style={{
            borderRadius: '4px',
            boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
            padding: '20px'
          }}
        >
          <MDBox color={'text'}>
            <div>
              <b>CNPJ:</b> {profile.cnpj}
            </div>
            <div>
              <b>Nome:</b> {profile.nome}
            </div>
            <div>
              <b>Telefone:</b> {profile.telefone}
            </div>
            <div>
              <b>Email:</b> {profile.email}
            </div>
            <div>
              <b>Status assinatura:</b> {profile.status === "ACTIVE" ? 'Ativa': 'Pendente'}
            </div>
          </MDBox>
        </div>
      </MDBox>
      <Grid container spacing={3} pt={6} pl={3}>
        <Grid xs={12} md={7} lg={4}>
          <MDBox mb={1.5}>
            <ComplexStatisticsCardWithBtn
              icon="checklist"
              color="success"
              title="Assinatura contrato"
              labelBtn="Assine já"
              onClickBtn={onClickContrato}
            />
          </MDBox>
        </Grid>
      </Grid>
      <Footer />
    </DashboardLayout>
  );
}

export default MeusDados;
