
// Material Dashboard 2 React example components
import DashboardLayout from "components/Layouts/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "components/FooterPages";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

import { criarUserFirebase } from "services/clientes";
import { salvarInformacoesNoFirestore } from "services/clientes";
import { obterDadosCliente } from "services/clientes";
import { atualizarInformacoesFireStore } from "services/clientes";
import { Autocomplete } from "@mui/material";

const entries = [
  { value:'https://bit.ly/ContratoCCXBank99', label: 'R$99,90'},
  { value:'https://bit.ly/ContratoCCXBank', label: 'R$149,90'}
]

function CadastroCliente() {
  const { uid } = useParams();
  const navigate = useNavigate();
  const isEdit = !!uid;

  const [inputs, setInputs] = useState({
    nome: "",
    email: "",
    password: "",
    usuarioPagaleve: "",
    senhaPagaLeve: "",
    cnpj:"",
    telefone: "",
    url: "",
    urlfgts: "",
    contrato: {}
  });

  const [errors, setErrors] = useState({
    nomeError: false,
    emailError: false,
    passwordError: false,
    usuarioPagaleveError: false,
    senhaPagaLeveError: false,
    cnpjError: false,
    telefoneError: false,
    urlError: false,
    contratoError: false,
    error: false,
    errorText: "",
  });

  useEffect(()=>{
    async function fecthData(){
      try{
        const res = await obterDadosCliente(uid)
        setInputs((prev)=>({
          ...prev,
          ...res,
          password: res.senha,
        }))

      }catch(error){
        console.log(error)
      }
    }
    if(isEdit){
      fecthData()
    }
  },[])

  useEffect(()=>{
    if(!isEdit){
      setInputs({
        nome: "",
        email: "",
        password: "",
        usuarioPagaleve: "",
        senhaPagaLeve: "",
        cnpj:"",
        telefone: "",
        url: "",
        contrato: "",
        urlfgts: "",
      });
    }
  },[isEdit])

  const changeHandler = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    const mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (inputs.nome.trim().length === 0) {
      setErrors({ ...errors, nomeError: true });
      return;
    }

    if (inputs.email.trim().length === 0 || !inputs.email.trim().match(mailFormat)) {
      setErrors({ ...errors, emailError: true });
      return;
    }

    if (inputs.password.trim().length < 7) {
      setErrors({ ...errors, passwordError: true });
      return;
    }

    if (inputs.cnpj.trim().length === 0) {
      setErrors({ ...errors, cnpjError: true });
      return;
    }

    if (inputs.email.trim().length === 0) {
      setErrors({ ...errors, emailError: true });
      return;
    }
    
    if (inputs.senhaPagaLeve.trim().length === 0) {
      setErrors({ ...errors, senhaPagaLeveError: true });
      return;
    }

    if (inputs.telefone.trim().length === 0) {
      setErrors({ ...errors, telefoneError: true });
      return;
    }

    try {
    
      const user = !isEdit ? await criarUserFirebase(inputs.email, inputs.password) : null;
      
      const values = {
        ...inputs,
        contrato: isEdit ? inputs.contrato : entries[0],
        uid: isEdit ? uid : user.uid,
        status: 'ACTIVE',
        type: 'CLIENTE',
      };

      if(isEdit){
        await atualizarInformacoesFireStore(values)
      } else {
        await salvarInformacoesNoFirestore(values)
      }


      setInputs({
        nome: "",
        email: "",
        password: "",
        usuarioPagaleve: "",
        senhaPagaLeve: "",
        cnpj:"",
        telefone: "",
        url: "",
        urlfgts: "",
      });

      setErrors({
        nomeError: false,
        emailError: false,
        passwordError: false,
        error: false,
        errorText: "",
      });
      
      navigate('/listarCliente');

    } catch (err) {
      setErrors({ ...errors, error: true, errorText: err.message });
      console.error(err);
    }
  };

  const handleInputSelect = (newValue) =>{
    setInputs((prev)=>({...prev, contrato: newValue}))
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
      <Card>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form" method="POST" onSubmit={submitHandler}>
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="Nome"
                variant="standard"
                fullWidth
                name="nome"
                value={inputs.nome}
                onChange={changeHandler}
                error={errors.nomeError}
                inputProps={{
                  autoComplete: "name",
                  form: {
                    autoComplete: "off",
                  },
                }}
              />
              {errors.nomeError && (
                <MDTypography variant="caption" color="error" fontWeight="light">
                  Campo obrigatório
                </MDTypography>
              )}
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="Email"
                variant="standard"
                fullWidth
                value={inputs.email}
                name="email"
                onChange={changeHandler}
                error={errors.emailError}
                disabled={isEdit}
                inputProps={{
                  autoComplete: "email",
                  form: {
                    autoComplete: "off",
                  },
                }}
              />
              {errors.emailError && (
                <MDTypography variant="caption" color="error" fontWeight="light">
                  Email precisa ser válido
                </MDTypography>
              )}
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Senha painel"
                variant="standard"
                fullWidth
                name="password"
                value={inputs.password}
                onChange={changeHandler}
                error={errors.passwordError}
                disabled={isEdit}
              />
              {errors.passwordError && (
                <MDTypography variant="caption" color="error" fontWeight="light">
                  A senha precisa ter pelo menos 7 digitos
                </MDTypography>
              )}
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="Usuario financeira"
                variant="standard"
                fullWidth
                name="usuarioPagaleve"
                value={inputs.usuarioPagaleve}
                onChange={changeHandler}
                inputProps={{
                  autoComplete: "name",
                  form: {
                    autoComplete: "off",
                  },
                }}
              />
              {errors.usuarioPagaleveError && (
                <MDTypography variant="caption" color="error" fontWeight="light">
                  Campo obrigatório
                </MDTypography>
              )}
            </MDBox>

            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Senha financeira"
                variant="standard"
                fullWidth
                name="senhaPagaLeve"
                value={inputs.senhaPagaLeve}
                onChange={changeHandler}
              />
              {errors.senhaPagaLeveError && (
                <MDTypography variant="caption" color="error" fontWeight="light">
                  Campo obrigatório
                </MDTypography>
              )}
            </MDBox>

            <MDBox mb={2}>
              <MDInput
                type="text"
                label="CNPJ"
                variant="standard"
                fullWidth
                name="cnpj"
                value={inputs.cnpj}
                onChange={changeHandler}
                disabled={isEdit}
              />
              {errors.cnpjError && (
                <MDTypography variant="caption" color="error" fontWeight="light">
                  Campo obrigatório
                </MDTypography>
              )}
            </MDBox>

            <MDBox mb={2}>
              <MDInput
                type="text"
                label="Telefone"
                variant="standard"
                fullWidth
                name="telefone"
                value={inputs.telefone}
                onChange={changeHandler}
              />
              {errors.telefoneError && (
                <MDTypography variant="caption" color="error" fontWeight="light">
                  Campo obrigatório
                </MDTypography>
              )}
            </MDBox>

            <MDBox mb={2}>
              <MDInput
                type="text"
                label="Url pix parcelado"
                variant="standard"
                fullWidth
                name="url"
                value={inputs.url}
                onChange={changeHandler}
              />
              {errors.urlError && (
                <MDTypography variant="caption" color="error" fontWeight="light">
                  Campo obrigatório
                </MDTypography>
              )}
            </MDBox>

            <MDBox mb={2}>
              <MDInput
                type="text"
                label="Url FGTS"
                variant="standard"
                fullWidth
                name="urlfgts"
                value={inputs.urlfgts}
                onChange={changeHandler}
              />
            </MDBox>

            <MDBox mb={2}>
              <Autocomplete
                disableClearable
                value={inputs.contrato}
                options={entries}
                onChange={(event, newValue) => {
                  handleInputSelect(newValue);
                }}
                size="small"
                renderInput={(params) => <MDInput label="Contrato" {...params} />}
              />
              {errors.contratoError && (
                <MDTypography variant="caption" color="error" fontWeight="light">
                  Campo obrigatório
                </MDTypography>
              )}
            </MDBox>

            
            



            {errors.error && (
              <MDTypography variant="caption" color="error" fontWeight="light">
                {errors.errorText}
              </MDTypography>
            )}
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth type="submit">
                {isEdit ? 'Salvar' : 'Cadastrar' }
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default CadastroCliente;
