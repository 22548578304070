
import BasicLayoutLanding from "components/Layouts/BasicLayout";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import Card from "@mui/material/Card";

import bgImage from "assets/images/bg-sign-in-basic.jpeg";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

import { criarUserFirebase } from "services/clientes";
import { salvarInformacoesNoFirestore } from "services/clientes";

const entries = [
  { value:'https://bit.ly/ContratoCCXBank99', label: 'R$99,90'},
  { value:'https://bit.ly/ContratoCCXBank', label: 'R$149,90'}
]

const INITIAL_STATE = {
  inputs: {
    nome: "",
    email: "",
    password: "",
    cnpj:"",
    telefone: ""
  },
  errors: {
    nomeError: false,
    emailError: false,
    passwordError: false,
    cnpjError: false,
    telefoneError: false,
    contratoError: false,
    error: false,
    errorText: "",
  }
}

function Cadastro() {
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({...INITIAL_STATE.inputs});
  const [errors, setErrors] = useState({...INITIAL_STATE.errors});

  const changeHandler = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    try {
      const isValidForm = validaForm();
      if(!isValidForm){
        return;
      }

      const user = await criarUserFirebase(inputs.email, inputs.password);
      
      const values = {
        ...inputs,
        contrato: entries[0],
        uid: user.uid,
        status: 'ACTIVE',
        type: 'CLIENTE',
      };
      
      await salvarInformacoesNoFirestore(values)
      
      clearInputs();
      resetErrors();
      
      navigate('/auth/login');

    } catch (err) {
      setErrors({ ...errors, error: true, errorText: err.message });
      console.error(err);
    }
  };

  const clearInputs = () => {
    setInputs({...INITIAL_STATE.inputs});
  }

  const resetErrors = () =>{
    setErrors({...INITIAL_STATE.errors});
  }

  const validaForm = () =>{
    const mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const letterFormat = /[a-zA-Z]/; 

    if (inputs.nome.trim().length === 0) {
      setErrors({ ...errors, nomeError: true });
      return false;
    }

    if (inputs.email.trim().length === 0 || !inputs.email.trim().match(mailFormat)) {
      setErrors({ ...errors, emailError: true });
      return false;
    }
    
    if (inputs.email.trim().length === 0) {
      setErrors({ ...errors, emailError: true });
      return false;
    }

    if (inputs.password.trim().length < 7) {
      setErrors({ ...errors, passwordError: true });
      return false;
    }

    if (inputs.cnpj.trim().length === 0) {
      setErrors({ ...errors, cnpjError: true });
      return false;
    }

    if (inputs.cnpj.trim().match(letterFormat)) {
      setErrors({ ...errors, cnpjError: true });
      return false;
    }
    
    if (inputs.telefone.trim().length === 0) {
      setErrors({ ...errors, telefoneError: true });
      return false;
    }

    if (inputs.telefone.trim().match(letterFormat)) {
      setErrors({ ...errors, telefoneError: true });
      return false;
    }
    return true
  }

  return (
    <BasicLayoutLanding image={bgImage}>
      <MDBox py={3}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Cadastre-se no CCX Dashboard
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form" method="POST" onSubmit={submitHandler}>
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="Nome"
                variant="standard"
                fullWidth
                name="nome"
                value={inputs.nome}
                onChange={changeHandler}
                error={errors.nomeError}
                inputProps={{
                  autoComplete: "name",
                  form: {
                    autoComplete: "off",
                  },
                }}
              />
              {errors.nomeError && (
                <MDTypography variant="caption" color="error" fontWeight="light">
                  Campo obrigatório
                </MDTypography>
              )}
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="Email"
                variant="standard"
                fullWidth
                value={inputs.email}
                name="email"
                onChange={changeHandler}
                error={errors.emailError}
                inputProps={{
                  autoComplete: "email",
                  form: {
                    autoComplete: "off",
                  },
                }}
              />
              {errors.emailError && (
                <MDTypography variant="caption" color="error" fontWeight="light">
                  Email precisa ser válido
                </MDTypography>
              )}
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Senha"
                variant="standard"
                fullWidth
                name="password"
                value={inputs.password}
                onChange={changeHandler}
                error={errors.passwordError}
              />
              {errors.passwordError && (
                <MDTypography variant="caption" color="error" fontWeight="light">
                  A senha precisa ter pelo menos 7 digitos
                </MDTypography>
              )}
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="CNPJ"
                variant="standard"
                fullWidth
                name="cnpj"
                value={inputs.cnpj}
                onChange={changeHandler}
              />
              {errors.cnpjError && (
                <MDTypography variant="caption" color="error" fontWeight="light">
                  Campo obrigatório / Dados inválidos
                </MDTypography>
              )}
            </MDBox>

            <MDBox mb={2}>
              <MDInput
                type="text"
                label="Telefone"
                variant="standard"
                fullWidth
                name="telefone"
                value={inputs.telefone}
                onChange={changeHandler}
              />
              {errors.telefoneError && (
                <MDTypography variant="caption" color="error" fontWeight="light">
                  Campo obrigatório / Dados inválidos
                </MDTypography>
              )}
            </MDBox>

            {errors.error && (
              <MDTypography variant="caption" color="error" fontWeight="light">
                {errors.errorText}
              </MDTypography>
            )}

            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth type="submit">
                Cadastrar
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
      </MDBox>
    </BasicLayoutLanding>
  );
}

export default Cadastro;
