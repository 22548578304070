// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "components/Layouts/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "components/FooterPages";
import ComplexStatisticsCardWithBtn from "examples/Cards/StatisticsCards/ComplexStatisticsCardWithBtn";
import { AuthContext } from "context";
import { useContext } from "react";
import AccordionUsage from "components/Accordion";
import { Icon } from "@mui/material";

function Dashboard() {
  const authContext = useContext(AuthContext);
  const { profile } = authContext;

  const onClickParcelex= () =>{
    window.open('https://login-centrax.parcelex.com.br/login', '_blank');
  }

  const onClickUrlFgts = () => {
    const url = `https://${profile.urlfgts}`;
    window.open(url, "_blank");
  };

  const userIncompleto = profile.contrato || profile.onboarding

  const fgts = !!profile.urlfgts; 

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {!profile.admin &&
        <MDBox py={3} height={'84vh'}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCardWithBtn
                  icon="pix"
                  title="Pix Parcelado"
                  color="light"
                  count={<div style={{
                    display: 'flex',
                    maxWidth: '8rem',
                    flexDirection: 'column',
                    fontSize: 'small'
                  }}>
                    Pix Parcelado
                  </div>}
                  labelBtn={!userIncompleto ? 'Acessar' : 'Gerando...'}
                  onClickBtn={onClickParcelex}
                  disabledBtn={userIncompleto}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCardWithBtn
                  icon="attach_money"
                  color="success"
                  title="Crédito cidadão"
                  labelBtn={fgts ? 'Acessar' : 'Gerando...'}
                  onClickBtn={onClickUrlFgts}
                  disabledBtn={!fgts}
                />
              </MDBox>
            </Grid>
          </Grid>
          <MDBox pt={6} pb={3} style={{display: 'grid', gap: '10px'}}>
            <AccordionUsage 
              listAccordion={[
                {
                  title: 'Plataforma CCX Bank',
                  defaultExpanded: true,
                  details: 
                  <div>
                    <span>Na plataforma do CCX Bank, você terá acesso a uma ampla gama de serviços personalizados para atender às suas necessidades. Além disso, você pode começar a explorar nossas opções de linha de crédito imediatamente. Basta seguir estas etapas:</span>
                    <br />
                    <br />
                    <div style={{display: 'flex'}}>
                      <Icon fontSize="medium" color="inherit">
                        east
                      </Icon>
                      <div>
                        Navegue até a seção de Linhas de Crédito.
                      </div>
                    </div>
                    <br /> 
                    <div style={{display: 'flex'}}>
                      <Icon fontSize="medium" color="inherit">
                        east
                      </Icon>
                      <div>
                        Selecione a linha de crédito "Pix em até 24x", e faça a aplicação.
                      </div>
                    </div>
                    <br />
                    <div style={{display: 'flex'}}>
                      <Icon fontSize="medium" color="inherit">
                        east
                      </Icon>
                      <div>
                        Não deixe também de assinar o contrato na seção "Meus dados/Contrato"
                      </div>
                    </div>
                    <br /> 
                    <div style={{display: 'flex'}}>
                      <Icon fontSize="medium" color="inherit">
                        east
                      </Icon>
                      <div>
                        Explore outras serviços da CCX na seção "CCX sale" e "CCX Dev"
                      </div>
                    </div>
                  </div>
                  
                },
              ]}
            />
          </MDBox>
        </MDBox>
      }
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
