import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import MDTypography from 'components/MDTypography';
import { useMaterialUIController } from 'context';

export default function AccordionUsage({
  listAccordion = [
    {
      title: '',
      defaultExpanded: false,
      details: '',
      buttons: null
    },
  ]
}) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  return (
    <div>
      {listAccordion.length > 0 && 
        listAccordion.map((item, index)=>{
          return (
            <Accordion defaultExpanded={item.defaultExpanded} key={`${index}`} style={{backgroundColor: `${darkMode ? '#202940' : '#FFF'}`}}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{color:`${darkMode ? '#FFF' : '#202940'}` }} />}
                aria-controls={`panel${index}-content`}
                id={`panel${index}-header`}
              >
                <MDTypography variant="h6" fontWeight="medium" color="text">
                  {item.title}
                </MDTypography>
              </AccordionSummary>
              <AccordionDetails>
                <MDTypography variant="h6" fontWeight="light" color="text">
                  {item.details}
                </MDTypography>
              </AccordionDetails>
              {item.buttons && 
                <AccordionActions>
                  {item.buttons}
                </AccordionActions>
              }
            </Accordion>
          )
        })
      }
    </div>
  );
}